import request from '@/utils/request'

// 查询发票申请记录列表
export function findInvoicesList(params) {
  return request({
    url: `/invoices`,
    method: 'get',
    params: params
  })
}

export function findInvoice(id) {
  return request({
    url: `/invoices/${id}`,
    method: 'get'
  })
}

export function newInvoice(id, data) {
  return request({
    url: `/invoices/${id}`,
    method: 'POST',
    data
  })
}
